<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>Thêm mới module</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action">
                        <md-button v-shortkey="['ctrl', 'l']" @shortkey="submit()" @click="submit()" class="md-raised md-primary"><span>L</span>ưu<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'i']" @shortkey="back()" @click="back()" class="md-raised">Quay lạ<span>i</span><md-tooltip>Quay lại (Ctrl + Q)</md-tooltip></md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="form-body">
                    <div class="row">
                        <div class="col l-6 m-6 c-12">
                            <md-field>
                                <label for="parentId">Module cha</label>
                                <md-select v-model="parentId" name="parentId" id="parentId">
                                    <md-option value="0">Module cha</md-option>
                                    <md-option v-for="parent in parents" :key="parent.id" :value="parent.id">{{parent.moduleName}}</md-option>
                                </md-select>
                            </md-field>

                            <md-field>
                                <label for="childParentId">Module cha cấp 2</label>
                                <md-select v-model="childParentId" name="childParentId" id="childParentId">
                                    <md-option value="0">Module cha</md-option>
                                    <md-option v-for="child in children" :key="child.id" :value="child.id">{{child.moduleName}}</md-option>
                                </md-select>
                            </md-field>

                            <md-field :class="{'md-invalid': submitted && $v.entity.moduleName.$error }">
                                <label for="moduleName">Tên module</label>
                                <md-input name="moduleName" v-model="entity.moduleName"></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.moduleName.required">Vui lòng nhập tên module</span>
                            </md-field>

                            <md-field :class="{'md-invalid': submitted && $v.entity.moduleCode.$error }">
                                <label for="moduleCode">Module code</label>
                                <md-input name="moduleCode" v-model="entity.moduleCode"></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.moduleCode.required">Vui lòng nhập module code</span>
                            </md-field>

                            <md-field :class="{'md-invalid': submitted && $v.entity.url.$error }">
                                <label for="url">Url</label>
                                <md-input name="url" v-model="entity.url"></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.url.required">Vui lòng nhập url</span>
                            </md-field>

                            <md-field :class="{'md-invalid': submitted && $v.entity.orderSort.$error }">
                                <label for="orderSort">Số thứ tự</label>
                                <md-input name="orderSort" v-mask="'##'" v-model="entity.orderSort"></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.orderSort.required">Vui lòng nhập số thứ tự</span>
                            </md-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moduleService from '../../../api/moduleService';
import messageBox from '../../../utils/messageBox';
import { required } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';

export default({
    metaInfo: {
        title: 'Thêm/Cập nhật module',
    },
    data() {
        return {
            title: '',
            id: 0,
            submitted: false,
            entity: { parentId: 0, moduleName: '', moduleCode: '', orderSort: 1, url: '' },
            parents: [],
            childParentId: 0,
            parentId: 0,
            children: []
        }
    },
    created() {
        this.id = this.$route.params.id;
        if(this.id > 0){
            this.title = 'Cập nhật module';
            this.getById();
        }
        else{
            this.title = 'Thêm mới module';
        }
        this.getParents();
    },
    methods: {
        ...mapActions('common', ['setLoading']),

        submit(){
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.entity.parentId = this.parentId;
            if (this.childParentId > 0){
                this.entity.parentId = this.childParentId;
            }
            if(this.id > 0){
                this.edit();
            }
            else{
                this.add();
            }
        },

        add(){
            this.setLoading(true);
            moduleService.add(this.entity).then((response) => {
                if(response.statusCode == 200){
                    messageBox.showMessage("Thêm mới thành công");
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => {  this.setLoading(false); });
        },

        edit(){
            this.setLoading(true);
            moduleService.edit(this.entity).then((response) => {
                if(response.statusCode == 200){
                    messageBox.showMessage("Cập nhật thành công");
                    this.$router.push('/module');
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => {  this.setLoading(false); });
        },

        getById(){
            this.setLoading(true);
            moduleService.getById(this.id).then((response) => {
                if(response.statusCode == 200){
                    this.entity = response.data;
                    this.getParent(this.entity.parentId);
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => {  this.setLoading(false); });
        },

        getParent(id){
            this.setLoading(true);
            moduleService.getById(id).then((response) => {
                if(response.statusCode == 200){
                    if(response.data.parentId === 0){
                        this.parentId = response.data.id;
                    }
                    else{
                        this.parentId = response.data.parentId;
                        this.childParentId = id;
                        this.getChildren();
                    }
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => {  this.setLoading(false); });
        },

        getParents(){
            moduleService.getParents()
            .then((response) => {
                if(response.statusCode == 200){
                    this.parents = response.data;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally();
        },

        getChildren(){
            moduleService.getChildren(this.parentId)
            .then((response) => {
                if(response.statusCode == 200){
                    this.children = response.data;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally();
        },

        back(){
            this.$router.push('/module');
        },
    },
    watch: {
        parentId: function () {
            this.getChildren();
        },
    },
    validations: {
        entity: { 
            moduleName: { required },
            moduleCode: { required },
            url: { required },
            orderSort: { required }
        }
    },
})
</script>
